@font-face {
  font-family: "acumin-pro";
  src: url("fonts/Acumin-RPro.woff");
}

@font-face {
  font-family: "acumin-pro";
  src: url("fonts/Acumin-BdPro.woff");
  font-weight: bold;
}

@font-face {
  font-family: "acumin-pro";
  src: url("fonts/Acumin-BdItPro.woff");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: "acumin-pro";
  src: url("fonts/Acumin-ItPro.woff");
  font-style: italic;
}


body {
  margin: 0;
  font-family: 'acumin-pro', -apple-system,'Helvetica Neue',sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --toastify-toast-width: 380px !important;
  --toastify-color-dark: #363636 !important;
}
