:root {
	--bgcolor: #121212;
}

.backdrop-text {
	stroke-linejoin: round;
	stroke-width: 5;
	stroke: var(--bgcolor);
}

.series-line-backdrop {
	fill: none;
	stroke-width: 6;
	stroke: var(--bgcolor);
}

.series-line {
	fill: none;
}

.tick > text {
	font-size: 14px;
}

.lines {
	opacity: 1;
}

.axis {
	color: white;
	opacity: 0.7;
}

hr {
	color: white;
	opacity: 0.7;
}

.legend {
	width: 100%;
	margin: 2rem 0;
	display: flex;
}

.spread-area {
	fill: #121212;
	opacity: 1;
}

.hidden {
	opacity: 0;
}

.legend-row {
	display: flex;
	flex: 1;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	text-align: center;
	/* border-right: 1px solid #666; */
	/* border-left: 1px solid #666; */
}

/* .legend-row:first-child {
	border-left-color: var(--bgcolor);
}

.legend-row:last-child {
	border-right-color: var(--bgcolor);
} */

.legend-rect {
	width: 24px;
	height: 6px;
	margin-bottom: 8px;
}

.legend-text {
	font-size: 1.1rem;
	font-weight: 700;
	letter-spacing: 1px;
	text-transform: uppercase;
	color: rgb(177, 177, 177);
	margin-bottom: 1rem;

	@media (max-width: 600px) {
		margin-bottom: 6px;
		font-size: 12px;
	}
}

.legend-value {
	font-size: 1.2rem;
	color: white;
	padding-bottom: 2px;

	@media (max-width: 600px) {
		font-size: 16px;
	}
}
