#faq {
    animation: appear 0.3s forwards;
    opacity: 0;
}

#faq p, #faq li {
    color: #e5e5e5;
}

#faq h2 {
    color: #f5f5f5;
}

#faq li {
    margin-bottom: 16px;
}

#faq li ul {
    margin-top: 8px;
}

#faq div div {
    margin-bottom: 40px;
}

#faq ul {
    list-style-type: disc;
}

@keyframes appear {
    0% {
        opacity: 0;
    }
    70% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}