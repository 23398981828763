.toggle-button {
    border-radius: 4px;
    position: relative;
    cursor: pointer;
}

.toggle-button .content {
    display: flex;
    position: relative;
    z-index: 1;
}

.toggle-button .content > span {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 36px;

    @media (max-width: 600px) {
        height: 42px;
    }
}

.toggle-button .switch {
    position: absolute;
    width: 50%;
    height: 100%;
    z-index: 0;
    border-radius: 4px;
    transition: transform 0.2s ease-in-out;
}

.toggle-button.left .switch {
    transform: translateX(0);
}


.toggle-button.right .switch {
    transform: translateX(100%);
}
